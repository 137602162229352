.microphone {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  min-width: 60%;
}
.state{
  font-size: 14px;
  color: white;
}

.terminal {
  min-height: 100px;
  display: flex;
  flex-direction: row;
  background-color: #333333;
  color: #ffffff;
  font-family: monospace;
  padding: 20px;
  border-radius: 5px;
  transition: ease-in 3s;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.buttons>button {
  background-color: #222;
  border-radius: 4px;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Farfetch Basis", "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  max-width: none;
  min-height: 22px;
  min-width: 10px;
  outline: none;
  overflow: hidden;
  padding: 9px 20px 8px;
  position: relative;
  text-align: center;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 180px;
  margin-bottom: 40px;
}

.buttons>button:hover,
:focus {
  opacity: .75;
}

.chatIntro {
  width: 10%;
  font-size: 20px;
  margin-right: 20px;
}

.response {
  width: 90%;
  text-align: left;
  font-size: 20px;
}

.transcript{
  margin: 20px 0px 40px 0px;
  font-size: 18px;
  color: white;
  font-weight: bold;
}